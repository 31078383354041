<template>
  <div>
    <v-card class="overflow-hidden">
      <v-card-title class="justify-center">
        <span class="text-h6 font-weight-bold navy--text"
          >Detalhes da Coleta - {{ getReceptionDetails.transportadora }} -
          {{ getReceptionDetails.coletaId }}
        </span>
        <span class="text-h6 font-weight-bold navy--text"> </span>
      </v-card-title>
      <div v-if="!isLoadingModal">
        <div v-if="getReceptionDetails">
          <v-card-text>
            <v-row justify="space-around">
              <v-col cols="6" align="start">
                <span class="text-body-1 font-weight-bold navy--text mx-1"
                  >Motorista:</span
                >
                <span>{{ getReceptionDetails.motoristaNome }}</span>
              </v-col>
              <v-col cols="6" align="start">
                <span class="text-body-1 font-weight-bold navy--text mx-1"
                  >CPF:</span
                >
                <span>{{ getReceptionDetails.motoristaCpf }}</span>
              </v-col>
            </v-row>
            <v-row justify="space-around">
              <v-col cols="6" align="start">
                <span class="text-body-1 font-weight-bold navy--text mx-1"
                  >Tipo do Veículo:</span
                >
                <span>{{ getReceptionDetails.tipoVeiculo }}</span>
              </v-col>
              <v-col cols="6" align="start">
                <span class="text-body-1 font-weight-bold navy--text mx-1"
                  >Placa:</span
                >
                <span>{{ getReceptionDetails.placaVeiculo }}</span>
              </v-col>
            </v-row>
            <v-row justify="start">
              <v-col cols="6" align="start">
                <span class="text-body-1 font-weight-bold navy--text mx-1"
                  >Chegada da Transportadora:</span
                >
                <span>{{
                  getReceptionDetails.dataRecepcaoMotorista | formatDateWithHour
                }}</span>
              </v-col>
              <v-col cols="6" align="start">
                <span class="text-body-1 font-weight-bold navy--text mx-1"
                  >Hora Inicial:</span
                >
                <span>{{
                  getReceptionDetails.dataIniciarEmbarque | formatHour
                }}</span>
              </v-col>
              <v-col cols="6" align="start">
                <span class="text-body-1 font-weight-bold navy--text mx-1"
                  >Data da Coleta:</span
                >
                <span>{{
                  getReceptionDetails.dataIniciarEmbarque | formatDate
                }}</span>
              </v-col>
            </v-row>
            <v-row
              justify="center"
              v-show="
                this.coleta.coletaAtrasada && this.coleta.motivoAtraso == ''
              "
            >
              <v-col cols="6">
                <v-select
                  v-model="status"
                  :items="itemsStatus"
                  hide-details
                  item-text="state"
                  item-value="value"
                  label="Motivo do Atraso"
                  persistent-hint
                  return-object
                  single-line
                  dense
                  outlined
                  clearable
                  solo
                  flat
                ></v-select>
              </v-col>
              <v-col>
                <v-btn
                  @click="sendMotivo"
                  :disabled="!status"
                  :loading="isLoadingModalMotivo"
                  color="primary"
                  class="navy--text text-truncate"
                  style="text-transform: none !important; font-weight: bolder"
                  >Enviar Motivo Atraso</v-btn
                >
              </v-col>
            </v-row>
            <v-row
              v-show="this.coleta.coletaAtrasada && this.coleta.motivoAtraso"
            >
              <v-col cols="12" align="start">
                <span class="text-body-1 font-weight-bold navy--text mx-1"
                  >Motivo do Atraso:</span
                >
                <span>{{ this.coleta.motivoAtraso }}</span>
              </v-col>
            </v-row>
          </v-card-text>
          <v-row justify="center" class="my-3" v-if="isRoleAdmin">
            <v-col align="center" cols="10" sm="5" md="3" lg="3" xl="3">
              <v-hover v-slot="{ hover }">
                <v-btn
                  rounded
                  block
                  @click="confirmReception"
                  :disabled="
                    coleta.coletaAtrasada && confirmacaoTransportadora()
                  "
                  :color="hover ? 'secondary' : 'primary'"
                  :loading="confirmLoading"
                  class="navy--text text-truncate"
                  style="text-transform: none !important; font-weight: bolder"
                  >Confirmar Recepção</v-btn
                >
              </v-hover>
            </v-col>
          </v-row>
          <v-row justify="center" class="my-3" v-else>
            <v-col align="center" cols="10" sm="5" md="3" lg="3" xl="3">
              <v-hover v-slot="{ hover }">
                <v-btn
                  rounded
                  block
                  @click="goToReception"
                  :disabled="coleta.coletaAtrasada && !coleta.motivoAtraso"
                  :color="hover ? 'secondary' : 'primary'"
                  class="navy--text text-truncate"
                  style="text-transform: none !important; font-weight: bolder"
                  >Recepção</v-btn
                >
              </v-hover>
            </v-col>
          </v-row>
        </div>
        <div v-else>
          <v-row class="py-6" justify="center">
            <v-col cols="6" align="center">
              <span>Não foi possível carregar os detalhes da coleta.</span>
            </v-col>
          </v-row>
        </div>
      </div>
      <v-col v-else>
        <v-progress-circular
          color="primary"
          indeterminate
          size="54"
        ></v-progress-circular>
      </v-col>
    </v-card>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data: () => ({
    isLoadingModal: false,
    liberationButton: false,
    confirmLoading: false,
    isLoadingModalMotivo: false,
    permission: "Admin",
    status: "",
    itemsStatus: [
      { state: "Atraso Sou Energy", value: 1 },
      { state: "Atraso Produção", value: 2 },
      { state: "Atraso Expedição", value: 3 },
      { state: "Atraso Estoque", value: 4 },
      { state: "Atraso Transportadora", value: 5 },
    ],
  }),
  props: {
    coletaId: {
      required: true,
      type: Number,
    },
    coleta: {
      required: true,
      type: Object,
    },
  },
  mounted() {
    this.startDetails();
  },
  computed: {
    ...mapGetters("reception", ["getReceptionDetails"]),
    ...mapGetters("login", [
      "getAdministrator",
      "getMyPermissions",
      "getRoleName",
    ]),
    isRoleAdmin() {
      return this.getRoleName.includes(this.permission);
    },
    confirmacaoTransportadora() {
      return this.coleta.status !== "CONFIRMACAO_TRANSPORTADORA";
    },
  },
  methods: {
    ...mapActions("reception", [
      "receptionDetails",
      "sendMotivoAtraso",
      "confirmDriver",
    ]),
    goToReception() {
      this.$router.replace({
        name: "Protocolo",
      });
    },
    async confirmReception() {
      this.confirmLoading = true;
      await this.confirmDriver(this.coletaId);
      this.confirmLoading = false;
      this.$emit("closeReceptionDetailsDialog");
    },

    async sendMotivo() {
      this.isLoadingModalMotivo = true;
      const motivo = this.status.state.toString();
      const body = {
        motivo,
        coletaId: this.coletaId,
      };
      debugger;

      const response = await this.sendMotivoAtraso(body);
      this.isLoadingModalMotivo = false;
      this.$emit("closeReceptionDetailsDialog", false);
      this.liberationButton = response;
    },
    async startDetails() {
      this.isLoadingModal = true;
      await this.receptionDetails(this.coletaId);
      this.isLoadingModal = false;
    },
  },
  filters: {
    formatDate(dateToBeFormatted) {
      return new Date(dateToBeFormatted).toLocaleDateString("pt-BR", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
    },
    formatHour(dateToBeFormatted) {
      const formatter = new Intl.DateTimeFormat("pt-BR", {
        hour: "2-digit",
        minute: "2-digit",
      });
      const date = new Date(dateToBeFormatted);
      return formatter.format(date);
    },
    formatDateWithHour(dateString) {
      const match = dateString.match(/(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2})/);
      return `${match[3]}/${match[2]}/${match[1]} - ${match[4]}:${match[5]}`;
    },
  },
};
</script>
<style lang=""></style>
